<template v-if="governorates && governorates.length > 0">
  <div>
    <vs-tabs v-if="governorates && governorates.length > 0" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
      <vs-tab :disabled="gov.warehouse !== $route.params.warehouseID" icon-pack="feather" icon="icon-map-pin" v-for="(gov, index) in governorates"
        :key="index" :label="gov.name">
        <governorate-orders @selected="selected = $event" :warehouse="gov.code"/>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import GovernorateOrders from './GovernorateOrders.vue'

export default {
  components: {
    GovernorateOrders
  },
  data () {
    return {
      selected: [],
      governoratesDic: {},
      governorates: [],
      allGovernorates: [
        {
          name: 'Alexandria',
          code: 'ALX',
          warehouse: 'OCT'
        },
        {
          name: 'Beni Suef',
          code: 'BNI',
          warehouse: 'OCT'
        },
        {
          name: 'Assiut',
          code: 'SYT',
          warehouse: 'OCT'
        },
        {
          name: 'Sahel',
          code: 'SHL',
          warehouse: 'OCT'
        },
        {
          name: 'Fayoum',
          code: 'FYM',
          warehouse: 'OCT'
        },
        {
          name: 'Suez',
          code: 'SUZ',
          warehouse: 'NC'
        },
        {
          name: 'Hurgada',
          code: 'HRG',
          warehouse: 'NC'
        },
        {
          name: 'Ismailiya',
          code: 'ISM',
          warehouse: 'NC'
        },
        {
          name: 'Damanhour',
          code: 'DMN',
          warehouse: 'DW'
        },
        {
          name: 'Monoufia',
          code: 'MNF',
          warehouse: 'DW'
        },
        {
          name: 'Mansoura',
          code: 'MNS',
          warehouse: 'DW'
        },
        {
          name: 'Benha',
          code: 'BNH',
          warehouse: 'DW'
        },
        {
          name: 'Tanta',
          code: 'TNT',
          warehouse: 'DW'
        },
        {
          name: 'Kafr Alsheikh',
          code: 'KFR',
          warehouse: 'DW'
        },
        {
          name: 'Mahla',
          code: 'MHL',
          warehouse: 'DW'
        }
      ]
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  watch: {
    '$route.params.warehouseID' () {
      this.loadGov()
    }
  },
  methods: {
    loadGov () {
      this.governorates = []
      this.allGovernorates.forEach(element => {
        if (element.warehouse === this.$route.params.warehouseID) {
          this.governorates.push(element)
        }
      })
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.loadGov()
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
